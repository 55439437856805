
import "./Info.scss";
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  STEP_ITEM,
  STEP_STATUS,
  COMMERCE_OPTION_ITEMS_DESCRIPTION,
  PRODUCT_NAME,
} from "@/shared/interface/newItem";
import { GET_CASE_BY_ID } from "@/shared/services/graphql-action";
import { InfoUtils } from "@/shared/utils/InfoUtils";
import { WORKITEM_PARAM_DEFINITION } from "@/shared/interface/workItem";
import { TimeUtil } from "@/shared/utils/TimeUtils";
const X2JS = require("x2js");
const x2js = new X2JS({ keepCData: true });

@Component({
  name: "InfoComponent",
  components: {},
})
export default class InfoComponent extends Vue {
  @Prop({ default: false }) caseId;
  isAllHide = false;
  noShowParameterNotice = "No parameters";
  stepStatus = {
    succeed: {
      icon: "el-icon-check",
      color: "#67c239",
    },
  };
  steps: any[] = [
    {
      name: "Product",
      model: "",
    },
    {
      name: "Executor",
      model: "",
    },
    {
      name: "Runbook",
      model: "",
    },
    {
      name: "Parameters",
      model: "",
    },
    {
      name: "Summary",
      model: "",
    },
  ];
  caseInfo: any = {
    product: "",
    executor: "",
    runbook: "",
    parameters: "",
    metaConfig: {},
    summary: "",
  };
  showCopyParam = false;
  currentJobdefinition: any;
  commerceOptionItemsDescription = COMMERCE_OPTION_ITEMS_DESCRIPTION;

  public created(): void {
    this.steps.forEach((o) => {
      this.setStepStatus(o, STEP_STATUS.succeed);
    });
    this.getData();
  }

  getProduct(): string {
    let productName = "";
    if (this.caseInfo.product) {
      productName =
        this.caseInfo.product === "SCV2"
          ? `C4C ${this.caseInfo.product}`
          : `Commerce ${this.caseInfo.product}`;
    }
    return productName;
  }

  get executorName(): string {
    const executors = [
      "CCV2 Rundeck",
      "CCV2 Argo",
      "SCV2 Rundeck",
      "SCV2 Argo",
    ];
    if (
      this.caseInfo.executor?.length > 0 &&
      !executors.includes(this.caseInfo.executor)
    ) {
      this.caseInfo.executor =
        this.caseInfo.executor.toUpperCase() === PRODUCT_NAME.ccv2
          ? "CCV2 Execution Engine"
          : "SCV2 Execution Engine";
    }
    return this.caseInfo.executor;
  }

  /**
   * Set the status of a step
   * @param {STEP_ITEM} step: current step object
   * @param {STEP_STATUS} status: the style object of status
   * @returns void
   */
  setStepStatus(step: STEP_ITEM, status: STEP_STATUS): void {
    Object.assign(step, this.stepStatus[status]);
  }

  getParsedJSONValue(str: string, key?: string): any {
    let parsedValue;
    try {
      parsedValue = key ? JSON.parse(str)[key] : JSON.parse(str);
    } catch (e) {
      parsedValue = str;
    }
    return parsedValue;
  }

  /**
   * get case info data by caseId
   * @returns void
   */
  getData(): void {
    this.$apollo
      .query({
        query: GET_CASE_BY_ID,
        variables: {
          id: this.caseId,
        },
      })
      .then((res) => {
        let data = res.data.hybris_workspace_item;
        if (res.data && data.length > 0) {
          this.displayCopyParameterLink(data[0]);
          this.caseInfo = {
            summary: data[0].summary,
            product: data[0].product,
            executor: data[0].executor,
            runbook:
              data[0].hybris_workspace_item_jobs.length > 0
                ? data[0].hybris_workspace_item_jobs[0].hybris_nb_cio_job
                    .job_name
                : "",
            parameters: JSON.parse(data[0].parameters),
            metaConfig: JSON.parse(data[0].meta_config),
          };
          if (
            Array.isArray(this.caseInfo.parameters) &&
            this.caseInfo.parameters.length > 0
          ) {
            let i = 0;
            this.caseInfo.parameters.forEach((item) => {
              if (item.hidden === "true") {
                i++;
              }
              if (item.type === "multiselect") {
                const strArr = this.getParsedJSONValue(item.value);
                item.value = strArr;
              }
              if (item.type === "commerce-specific-cascader") {
                const strArr = item.value.split(",");
                if (strArr.length > 2) {
                  const byteSize = 1024;
                  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
                  let sizeValue = parseFloat(strArr[2]);
                  let formattedSize = "0 Bytes";

                  if (sizeValue > 0) {
                    const i = Math.floor(
                      Math.log(sizeValue) / Math.log(byteSize)
                    );
                    formattedSize = `${(
                      sizeValue / Math.pow(byteSize, i)
                    ).toFixed(2)} ${sizes[i]}`;
                  }

                  item.value = `${strArr[0]},${strArr[1]},${formattedSize}`;
                }
              }
            });
            if (i === this.caseInfo.parameters.length) {
              this.isAllHide = true;
            }
          }
        }
      });
  }

  displayCopyParameterLink(workItem: any): void {
    const owner = workItem.created_by;
    const userIDNameStr = process.env.VUE_APP_USERID_SYSNAME_MAP;
    const userIdSysNameMap = userIDNameStr ? JSON.parse(userIDNameStr) : [];
    const isOwnerExternalSystem = userIdSysNameMap.some(
      (user) => user.userId === owner
    );
    if (workItem.hybris_workspace_item_jobs.length > 0) {
      const job = workItem.hybris_workspace_item_jobs[0].hybris_nb_cio_job;
      const isPrivateWorkItem = job.is_private;
      if (job.is_deleted || !job.is_enabled || isOwnerExternalSystem)
        this.showCopyParam = false;
      else if (isPrivateWorkItem) {
        this.showCopyParam = owner === this.$store.state.userId; // show copy param for private runbook for the user who is the designer of the runbook
      } else this.showCopyParam = true; // show copy param for public runbook which is enabled and not deleted
      this.currentJobdefinition = job.job_definition;
    }
  }

  goToNewWorkItem(paramsObj: WORKITEM_PARAM_DEFINITION[]): void {
    const jobDefinitionJSON = x2js.xml2js(atob(this.currentJobdefinition));
    const latestParamOptions =
      jobDefinitionJSON.joblist.job.context.options.option;
    const isIdenticalParams = InfoUtils.isIdenticalParams(
      paramsObj,
      latestParamOptions
    );
    // const message = `<p>Since one or more parameter definitions are changed, none of the parameters will be copied.
    //                   Only "Product","Executor" and "Runbook" will.</p>
    //                  <p>Do you want to proceed?</p>`;

    const message = `Since the Runbook options are changed by the Runbook developer, options will not be copied.
                    Do you want to proceed?`;
    const workItemQuery = {
      product: this.caseInfo.product,
      executor: this.caseInfo.executor,
      runbook: this.caseInfo.runbook,
      caseId: this.caseId,
      notify: this.caseInfo.metaConfig.email.notify ?? false,
    };
    if (isIdenticalParams) {
      const targetRoute = `/new?clone=true`;
      const newItemRoute = this.$router.resolve({
        path: targetRoute,
        query: workItemQuery,
      });
      window.open(newItemRoute.href);
    } else {
      this.$confirm(message, "Notice!", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          const targetRoute = `/new?clone=false`;
          const newItemRoute = this.$router.resolve({
            path: targetRoute,
            query: workItemQuery,
          });
          window.open(newItemRoute.href);
        })
        .catch(() => {
          console.warn(
            "No Parameters copied since some option definitions mismatch"
          );
        });
    }
  }
  /*
   * filter return data
   * @param {object} item: date source
   * @returns Returns date type or plain data in the specified format
   */
  filterData(item: Record<string, any>): string {
    if (item.isDate === "true") {
      if (!item.dateFormat) {
        return item.value;
      }
      if (!item.value) {
        return "-";
      }
      let dateformat = item.dateFormat
        .replace("(range)", "")
        .replace("dd", "DD");
      const hasTimeZone: boolean = item.value.includes("utc_timestamp");
      const dateformatValues = hasTimeZone
        ? JSON.parse(item.value).utc_timestamp
        : item.value;
      const timeZone =
        hasTimeZone && JSON.parse(item.value)?.timezone !== ""
          ? JSON.parse(item.value)?.timezone
          : "UTC";

      if (dateformatValues === "") {
        return "Empty Value";
      }
      if (dateformatValues.indexOf(",") > -1) {
        return TimeUtil.getFormattedDateRange(
          dateformatValues,
          dateformat,
          timeZone,
          hasTimeZone
        );
      } else {
        return TimeUtil.getFormattedDate(
          dateformatValues,
          dateformat,
          timeZone,
          hasTimeZone
        );
      }
    } else {
      let dataStr = item.value;
      if (dataStr.indexOf(",") > -1 && item?.type?.indexOf("select") !== -1) {
        return dataStr.replaceAll(",", "<br/>");
      } else {
        return dataStr;
      }
    }
  }
}
