
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  EXECUTION_STATUS,
  EXECUTION_REPORT_STATUS,
} from "@/shared/interface/workItemDetails";
import cloneDeep from "lodash.clonedeep";
import { WORKITEM_JOB_TYPE } from "@/shared/interface/newItem";
import moment_tz from "moment-timezone";
import moment from "moment";
import { DownloadUtils } from "@/shared/utils/DownloadUtils";
import { forkJoin } from "rxjs";
import "./Summary.scss";
@Component({
  name: "SummaryTableComponent",
  components: {},
})
export default class SummaryTableComponent extends Vue {
  @Prop({ default: false }) summaryData;
  @Prop({ default: false }) executeReportStatus;
  @Prop({ default: WORKITEM_JOB_TYPE.ondemand }) jobType;

  DEFAULT_JOB_TYPE: WORKITEM_JOB_TYPE = WORKITEM_JOB_TYPE.ondemand;
  EXECUTION_STATUS = EXECUTION_STATUS;
  EXECUTION_REPORT_STATUS = EXECUTION_REPORT_STATUS;
  tableTitleBasic: any = [
    { prop: "status", label: "EXECUTION STATUS", width: 200, fixed: false },
    { prop: "startTime", label: "RUN START", width: 150, fixed: false },
    { prop: "endTime", label: "RUN END", width: 150, fixed: false },
    { prop: "userId", label: "TRIGGERED BY", width: 150, fixed: false },
    { prop: "executionId", label: "EXECUTION ID", width: 150, fixed: false },
    { prop: "scheduleType", label: "SCHEDULE TYPE", width: 150, fixed: false },
  ];
  tableTitle: any = [];
  tableData: any = [];
  downloadingReport = false;
  timezoneSettings = {
    currentTimezone: moment_tz.tz.guess(),
    currentTimezoneOffset: Math.floor(moment().utcOffset() / 60),
  };
  filePathArr = [];
  isPilotUser = false;

  @Watch("summaryData", { deep: true, immediate: true })
  setSummaryData(val: Record<string, any>): void {
    this.tableData = cloneDeep(val);
    if (this.tableData && this.tableData[0]) {
      this.tableTitle = this.tableData[0].filePath
        ? this.tableTitleBasic.concat({
            prop: "filePath",
            label: "REPORT",
            fixed: "right",
            width: 150,
          })
        : this.tableTitleBasic;
      this.getUserId();
      this.filePathArr = this.tableData[0]?.filePath?.split(";");

      const isPilotUser = this.$store.state.userRoles.some((role) =>
        role.endsWith("iopsv2_Commerce_ContentDeveloperPilot")
      );

      if (
        this.tableData[0].status === EXECUTION_STATUS.succeed &&
        this.tableData[0].ITSM_CR_Creation &&
        isPilotUser
      ) {
        this.tableTitle.push({
          prop: "ITSM_CR_Creation",
          label: "ITSM CR",
          fixed: false,
          width: 150,
        });
      }
    }
  }
  /**
   * download execution report
   * @param { Object} data: the current summary data
   * @returns void
   */
  downloadReport(data: Record<string, any>): void {
    this.downloadingReport = true;
    const filePathArr = data.filePath.split(";");
    const filePaths = Array.isArray(filePathArr)
      ? filePathArr
      : [data.filePath];

    const fileObs$ = DownloadUtils.getDownloadFileDataObs(
      data.userId,
      filePaths
    );

    const zipFileName = `${this.$route.query?.id}_${
      this.tableData[0]?.executionId || ""
    }`;
    forkJoin(fileObs$).subscribe((blobs) => {
      DownloadUtils.downloadReportFiles(zipFileName, blobs);
      this.downloadingReport = false;
    });
  }

  handleDownloadClick(): void {
    this.$emit("onDownload", true);
    this.$store.commit("showDownloadingDialog");
  }

  /**
   * gets external system name for external system user id
   * @returns void
   */
  getUserId(): void {
    const userIDNameStr = process.env.VUE_APP_USERID_SYSNAME_MAP;
    if (userIDNameStr) {
      this.tableData.forEach((tableData) => {
        const userIdSysNameMap = JSON.parse(userIDNameStr);
        const externalSystemIndex = userIdSysNameMap.findIndex(
          (item) =>
            item.userId.toLowerCase().trim() ===
            tableData.userId?.toLowerCase().trim()
        );
        if (externalSystemIndex !== -1)
          tableData.userId =
            userIdSysNameMap[externalSystemIndex].externalSystemName;
      });
    }
  }
}
